import React from 'react'
import { Input } from '@builtbypixel/plasma'

const Text = (props) => {
   const { setActive, onChange, onBlur, value, children, type, ...rest } = props
   return (
      <Input
         onChange={(e) =>
            onChange(
               type === 'number' ? parseInt(e.target.value) : e.target.value
            )
         }
         onBlur={() => {
            onBlur && onBlur()
            setActive(false)
         }}
         onFocus={() => setActive(true)}
         value={value}
         required={false}
         isRequired={false}
         minWidth={type === 'number' ? '100px' : '200px'}
         {...rest}
      />
   )
}

export default Text
