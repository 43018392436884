/** @jsx jsx */
import { jsx } from '@emotion/core'
import { forwardRef } from 'react'
import { useFormControl } from '../FormControl'
import PseudoBox from '../PseudoBox'
import useInputStyle from './styles'
import PropTypes from 'prop-types'

const Input = forwardRef((props, ref) => {
  const {
    size,
    variant,
    as,
    'aria-label': ariaLabel,
    'aria-describedby': ariaDescribedby,
    isReadOnly,
    isFullWidth,
    isDisabled,
    isInvalid,
    isRequired,
    autocomplete,
    focusBorderColor,
    errorBorderColor,
    onClick,
    onBlur,
    onFocus,
    type,
    value,
    ...rest
  } = props

  const inputStyleProps = useInputStyle(props)
  const formControl = useFormControl(props)

  return (
    <PseudoBox
      ref={ref}
      as={as}
      readOnly={formControl.isReadOnly}
      aria-readonly={isReadOnly}
      disabled={formControl.isDisabled}
      aria-label={ariaLabel}
      aria-invalid={formControl.isInvalid}
      required={formControl.isRequired}
      aria-required={formControl.isRequired}
      aria-disabled={formControl.isDisabled}
      aria-describedby={ariaDescribedby}
      autocomplete={formControl.autocomplete}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      value={value ? value : ''}
      {...inputStyleProps}
      {...rest}
      type={type}
    />
  )
})

Input.displayName = 'Input'

Input.defaultProps = {
  size: 'md',
  as: 'input',
  variant: 'outline',
  isFullWidth: true,
  focusBorderColor: 'primary',
  errorBorderColor: 'red',
  autocomplete: 'off',
  type: 'text',
  value: ''
}

Input.propTypes = {
  /**
   * The variant type of the button
   */
  variant: PropTypes.oneOf(['outline', 'filled', 'unstyled', 'flushed']),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  /**
   * Colour theme of the button - can relate to colours in your theme colour object
   */
  variantColor: PropTypes.oneOfType([
    PropTypes.oneOf(['gray', 'primary', 'red', 'green']),
    PropTypes.string
  ]),
  /**
   * Is the input full width?
   */
  isFullWidth: PropTypes.bool,
  /**
   * The colour of the border when input is in focus
   */
  focusBorderColor: PropTypes.string,
  /**
   * The colour of the border when input is in error
   */
  errorBorderColor: PropTypes.string,
  /**
   * The name of the input
   */
  name: PropTypes.string,
  /**
   * Input placeholder
   */
  placeholder: PropTypes.string,
  /**
   * Sets the input to read only
   */
  isReadOnly: PropTypes.bool,
  /**
   * Disables the input
   */
  isDisabled: PropTypes.bool,
  /**
   * Sets the input to error state
   */
  isInvalid: PropTypes.bool,
  /**
   * Input required state
   */
  isRequired: PropTypes.bool
}

export default Input
