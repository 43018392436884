/** @jsx jsx */
import { jsx } from "@emotion/core";
import { createContext, useContext, forwardRef } from "react";
import PropTypes from "prop-types";
import Box from "../Box";

export const useFormControl = (props) => {
  const context = useFormControlContext();
  if (!context) {
    return props;
  }
  const keys = Object.keys(context);
  return keys.reduce((acc, prop) => {
    /** Giving precedence to `props` over `context` */
    acc[prop] = props[prop];

    if (context) {
      if (props[prop] == null) {
        acc[prop] = context[prop];
      }
    }

    return acc;
  }, {});
};

const FormControlContext = createContext();

export const useFormControlContext = () => useContext(FormControlContext);

const FormControl = forwardRef(
  (
    { isInvalid, isRequired, isDisabled, isReadOnly, label, help, ...rest },
    ref
  ) => {
    const context = {
      isRequired,
      isDisabled,
      isInvalid,
      isReadOnly,
      label,
      help,
    };

    return (
      <FormControlContext.Provider value={context}>
        <Box role="group" ref={ref} {...rest} />
      </FormControlContext.Provider>
    );
  }
);

FormControl.displayName = "FormControl";

FormControl.propTypes = {
  /**
   * Is the field required
   */
  isRequired: PropTypes.bool,
  /**
   * Is the field disabled
   */
  isDisabled: PropTypes.bool,
  /**
   * Is the field invalid
   */
  isInvalid: PropTypes.bool,
  /**
   * Is the field read only?
   */
  isReadOnly: PropTypes.bool,
  /**
   * The field label
   */
  label: PropTypes.string,
  /**
   * The field help text
   */
  help: PropTypes.string,
};

FormControl.defaultProps = {
  isRequired: false,
  isDisabled: false,
  isInvalid: false,
  label: null,
  help: null,
};

export default FormControl;
