import React, { memo, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import {
   FormControl,
   FormLabel,
   PseudoBox,
   PopoverTrigger,
   PopoverContent,
   Popover,
   useColorMode,
   FormHelperText,
   FormErrorMessage,
   useTheme
} from '@builtbypixel/plasma'
import { ErrorMessage } from '@hookform/error-message'

import Text from '../../Fields/Text'
import Date from '../../Fields/DatePicker'
import NativeSelect from '../../Fields/NativeSelect'
import Select from '../../Fields/Select'
import TextArea from '../../Fields/TextArea'
import RichText from '../../Fields/RichText'
import File from '../../Fields/File'
import Switch from '../../Fields/Switch'
import { Repeater } from '../../Fields/Repeater'
import Html from '../../Fields/Html'
import { List } from '../../Fields/List'

import ColourPicker from '../../Fields/ColourPicker'

const Field = memo((props) => {
   const {
      component,
      label,
      name,
      isRequired,
      defaultValue,
      guidance,
      help,
      rules,
      auto = false,
      showField = true,
      isConditional,
      isRepeater,
      ...rest
   } = props
   const { control } = useFormContext()
   const { colorMode } = useColorMode()
   const [active, setActive] = useState(false)
   const theme = useTheme()
   const borderColour =
      colorMode === 'dark'
         ? theme.colors.whiteAlpha[300]
         : theme.colors.blackAlpha[200]

   const getField = (props) => {
      switch (component) {
         case 'text':
            return <Text setActive={setActive} {...props} />
         case 'html':
            return <Html setActive={setActive} {...props} />
         case 'date':
            return <Date setActive={setActive} {...props} />
         case 'nativeSelect':
            return <NativeSelect setActive={setActive} {...props} />
         case 'select':
            return <Select setActive={setActive} {...props} />
         case 'textarea':
            return <TextArea setActive={setActive} {...props} required={null} />
         case 'richtext':
            return <RichText setActive={setActive} active={active} {...props} />
         case 'switch':
            return <Switch setActive={setActive} active={active} {...props} />
         case 'file':
            return <File setActive={setActive} active={active} {...props} />
         case 'list':
            return <List setActive={setActive} active={active} {...props} />
         case 'colour':
            return (
               <ColourPicker setActive={setActive} active={active} {...props} />
            )

         /* eslint-disable */
         default:
            const Comp = component
            return <Comp {...props} setActive={setActive} active={active} />
      }
   }

   return component === 'repeater' ? (
      <Repeater setActive={setActive} active={active} name={name} {...props} />
   ) : showField ? (
      <Controller
         name={name}
         defaultValue={defaultValue || ''}
         rules={rules}
         control={control}
         render={({
            field: { onChange, onBlur, value, name },
            formState: { errors }
         }) => (
            <PseudoBox
               transition='all 0.3s ease-in-out'
               p={isRepeater ? '7px' : '0px'}
               mb={!auto ? 0 : isRepeater ? 0 : 1}
               w='100%'
               value={value}
               style={{
                  borderColor: errors[name]
                     ? theme.colors.error
                     : active
                     ? theme.colors.secondary
                     : borderColour
               }}
            >
               <Popover
                  arrow
                  placement='bottom'
                  size='md'
                  trigger={guidance ? 'click' : null}
               >
                  <FormControl mb={isRepeater ? 0 : 9}>
                     {label && (
                        <FormLabel htmlFor={name}>
                           {label} {isRequired && '*'}
                        </FormLabel>
                     )}

                     <PopoverTrigger w='100%'>
                        {getField({
                           onChange,
                           onBlur,
                           value,
                           name,
                           isRepeater,
                           ...rest
                        })}
                     </PopoverTrigger>

                     {help && <FormHelperText>{help}</FormHelperText>}

                     <ErrorMessage
                        errors={errors}
                        name={name}
                        render={({ message }) => (
                           <FormErrorMessage>
                              {message || 'Required'}
                           </FormErrorMessage>
                        )}
                     />
                  </FormControl>
                  <PopoverContent
                     bg='secondary'
                     color='white'
                     px={1}
                     py={1}
                     textAlign='center'
                     fontSize='12px'
                  >
                     {guidance}
                  </PopoverContent>
               </Popover>
            </PseudoBox>
         )}
      />
   ) : null
})

export default Field
