import React, { useEffect, useState } from 'react'
import {
   Box,
   Button,
   Flex,
   PopConfirm,
   IconButton,
   Icon,
   Text,
   Image as NucleusImage
} from '@builtbypixel/plasma'
import { Image, Transformation } from 'cloudinary-react'
import { use100vh } from 'react-div-100vh'
import MediaLibrary from './MediaLibrary'
import { FiTrash } from 'react-icons/fi'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { CgMenuGridO } from 'react-icons/cg'
import { HiCheck } from 'react-icons/hi'
import { MdPictureAsPdf } from 'react-icons/md'
import ImagePreview from '../../../components/common/ImagePreview'

const MediaLibraryComponent = ({
   onChange,
   modalButton,
   max_files,
   multiple,
   showOptions,
   isRepeater,
   value,
   ...rest
}) => {
   const [open, setOpen] = useState(false)
   const height = use100vh()

   const [selectedFiles, setSelectedFiles] = useState([])

   const handleSelected = (files) => {
      if (files) {
         setSelectedFiles((old) => [...old, ...files.assets])
         onChange([...selectedFiles, ...files.assets])
      }
   }

   useEffect(() => {
      if (value) {
         setSelectedFiles(value)
      }
   }, [value])

   const onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
         return
      }

      const items = reorder(
         selectedFiles,
         result.source.index,
         result.destination.index
      )

      setSelectedFiles(items)
      onChange(items)
   }

   const removeImage = (index) => {
      let _uploaded = [...selectedFiles]
      _uploaded.splice(index, 1)
      setSelectedFiles(_uploaded)
      onChange(_uploaded)
   }

   const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)

      return result
   }

   return (
      <React.Fragment>
         <Box onClick={() => setOpen(true)}>
            <Button>Upload File(s)</Button>
         </Box>

         <Box w='100%'>
            {selectedFiles && selectedFiles.length !== 0 && (
               <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='droppable'>
                     {(provided, snapshot) => (
                        <Box
                           {...provided.droppableProps}
                           ref={provided.innerRef}
                           w='100%'
                           mt='10px'
                           mb='10px'
                        >
                           {selectedFiles.map((item, index) => (
                              <Draggable
                                 key={`file-${index}`}
                                 draggableId={
                                    item.original_filename
                                       ? item.original_filename
                                       : `file-${index}`
                                 }
                                 index={index}
                              >
                                 {(provided, snapshot) => (
                                    <Flex
                                       ref={provided.innerRef}
                                       align='center'
                                       justify='flex-start'
                                       {...provided.draggableProps}
                                       {...getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                       )}
                                    >
                                       <Icon
                                          px='10px'
                                          {...provided.dragHandleProps}
                                          color='global.text'
                                       >
                                          <CgMenuGridO color='global.text' />
                                       </Icon>
                                       {item.type === 'application/pdf' ||
                                       item.format === 'application/pdf' ? (
                                          <Icon
                                             px='6px'
                                             fontSize='21px'
                                             h='37px'
                                             display='flex'
                                             alignItems='center'
                                             color='secondary'
                                          >
                                             <MdPictureAsPdf />
                                          </Icon>
                                       ) : (
                                          <ImagePreview
                                             url={item.url}
                                             w='60px'
                                             flexShrink={0}
                                          >
                                             <Box
                                                w={isRepeater ? '40px' : '60px'}
                                                h='100%'
                                                flexShrink={0}
                                                mr='15px'
                                                objectFit='cover'
                                             >
                                                {item.public_id ? (
                                                   <Image
                                                      publicId={item.public_id}
                                                      secure='true'
                                                   >
                                                      <Transformation
                                                         width='150'
                                                         height='150'
                                                         crop='thumb'
                                                      />
                                                   </Image>
                                                ) : (
                                                   <NucleusImage
                                                      src={item.url}
                                                      w={
                                                         isRepeater
                                                            ? '40px'
                                                            : '60px'
                                                      }
                                                      objectFit='cover'
                                                   />
                                                )}
                                             </Box>
                                          </ImagePreview>
                                       )}
                                       {!isRepeater && (
                                          <Flex direction='column'>
                                             <Text
                                                maxWidth={{
                                                   xs: '120px',
                                                   md: '200px'
                                                }}
                                                fontSize={{
                                                   xs: '11px',
                                                   sm: '12px'
                                                }}
                                                opacity={0.7}
                                                isTruncated
                                             >
                                                {item.original_filename}
                                             </Text>
                                             <Text
                                                fontWeight='semibold'
                                                fontSize='11px'
                                             >
                                                {item.format}
                                             </Text>
                                          </Flex>
                                       )}
                                       <Flex as='span' ml='auto' align='center'>
                                          <Icon
                                             color='success'
                                             mr='0px'
                                             fontSize='20px'
                                          >
                                             <HiCheck />
                                          </Icon>

                                          <PopConfirm
                                             onConfirm={() =>
                                                removeImage(index)
                                             }
                                             title='Are you sure you want to remove this image?'
                                          >
                                             <IconButton
                                                variant='link'
                                                ml='5px'
                                                icon={<FiTrash />}
                                             />
                                          </PopConfirm>
                                       </Flex>
                                    </Flex>
                                 )}
                              </Draggable>
                           ))}
                           {provided.placeholder}
                        </Box>
                     )}
                  </Droppable>
               </DragDropContext>
            )}
         </Box>

         {open && (
            <React.Fragment>
               <Box
                  w='100vw'
                  h={`calc(${height}px - 30px)`}
                  rounded='lg'
                  overflow='hidden'
                  left={0}
                  position='fixed'
                  top={0}
                  zIndex={9999}
                  p='10px'
               >
                  <Flex h='30px' align='center' pb='5px'>
                     <Button
                        ml='auto'
                        variant='ghost'
                        variantColor='white'
                        onClick={() => setOpen(false)}
                     >
                        Close
                     </Button>
                  </Flex>
                  <MediaLibrary
                     setOpen={setOpen}
                     max_files={max_files}
                     multiple={multiple}
                     handleSelect={handleSelected}
                     showOptions={showOptions}
                  />
               </Box>
               <Box
                  w='100vw'
                  content="' '"
                  left={0}
                  h='100vh'
                  top={0}
                  bg='rgba(0,0,0,0.8)'
                  position='fixed'
                  zIndex={9000}
               />
            </React.Fragment>
         )}
      </React.Fragment>
   )
}

const getItemStyle = (isDragging, draggableStyle) => ({
   // some basic styles to make the items look a bit nicer
   userSelect: 'none',
   mb: '5px',
   rounded: 'lg',
   boxShadow: 'sm',
   border: '1px',
   borderColor: isDragging ? 'primary' : 'global.borderColour',
   bg: isDragging ? 'global.elementBgAlt' : 'global.elementBg',
   pr: '10px',
   ...draggableStyle
})

export default MediaLibraryComponent
