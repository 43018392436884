/** @jsx jsx */
import { Children, cloneElement, isValidElement } from 'react';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import Flex from '../Flex';

const Stack = ({ children, isInline, spacing, ...rest }) => {
    return (
        <Flex
            mx={isInline ? `-${spacing}` : 0}
            py={!isInline ? `-${spacing}` : 0}
            direction={isInline ? 'row' : 'column'}
            {...rest}
        >
            {Children.map(children, (child) =>
               isValidElement(child) && cloneElement(child, {
                    ...child.props,
                    mx: isInline ? spacing : 0,
                    my: !isInline ? spacing : 0,
                })
            )}
        </Flex>
    );
};

Stack.displayName = 'Stack';

Stack.propTypes = {
    spacing: PropTypes.string,
};

export default Stack;
