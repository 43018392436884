/** @jsx jsx */
import { jsx } from '@emotion/core';
import { forwardRef } from 'react';
import Flex from '../Flex';
import Box from '../Box';
import { useColorMode, useTheme } from '../ColourModeProvider';
import ControlBox from '../ControlBox';
import VisuallyHidden from '../VisuallyHidden';
import PropTypes from 'prop-types';
import { isDarkColor } from '../theme/colors-utils';

const switchSizes = {
    sm: {
        width: '1.375rem',
        height: '0.75rem',
    },
    md: {
        width: '1.875rem',
        height: '1rem',
    },
    lg: {
        width: '2.875rem',
        height: '1.5rem',
    },
};

const Switch = forwardRef(
    (
        {
            id,
            name,
            value,
            'aria-label': ariaLabel,
            'aria-labelledby': ariaLabelledBy,
            color,
            isChecked,
            size,
            isDisabled,
            isInvalid,
            onChange,
            onBlur,
            onFocus,
            children,
            ...rest
        },
        ref
    ) => {
        const { colorMode } = useColorMode();
        const theme = useTheme();
        const width = switchSizes[size] && switchSizes[size]['width'];
        const height = switchSizes[size] && switchSizes[size]['height'];

        const stylesProps = {
            rounded: 'full',
            justifyContent: 'flex-start',
            width,
            height,
            bg: colorMode === 'dark' ? 'whiteAlpha.400' : 'gray.300',
            boxSizing: 'content-box',
            mr: '10px',
            p: '2px',
            _checked: {
                bg: isDarkColor(theme.colors[color]) ? '#25CB8B' : '#27D592',
            },
            _child: {
                transform: `translateX(0)`,
            },
            _checkedAndChild: {
                transform: `translateX(calc(${width} - ${height}))`,
            },
            _focus: {
                boxShadow: 'none',
            },
            _hover: {
                cursor: 'pointer',
            },
            _checkedAndHover: {
                cursor: 'pointer',
            },
            _disabled: {
                opacity: 0.4,
                cursor: 'not-allowed',
            },
        };

        return (
            <Flex as='label' display='inline-flex' align='center' {...rest}>
                <VisuallyHidden
                    as='input'
                    type='checkbox'
                    aria-label={ariaLabel}
                    aria-labelledby={ariaLabelledBy}
                    id={id}
                    ref={ref}
                    name={name}
                    value={value}
                    aria-invalid={isInvalid}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    checked={isChecked}
                    disabled={isDisabled}
                />
                <ControlBox {...stylesProps}>
                    <Box
                        bg='white'
                        transition='transform 250ms'
                        rounded='full'
                        size={height}
                    />
                </ControlBox>
                <Box as='span' fontSize={size}>
                    {children}
                </Box>
            </Flex>
        );
    }
);

Switch.displayName = 'Switch';

Switch.defaultProps = {
    color: 'primary',
    size: 'md',
};

Switch.propTypes = {
    /**
     * The size of the switch
     */
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    /**
     * The size of the switch
     */
    value: PropTypes.bool,
    /**
     * Set the checked state
     */
    isChecked: PropTypes.bool,
    /**
     * Set the disabled state
     */
    isDisabled: PropTypes.bool,
    /**
     * Set the background colour
     */
    color: PropTypes.string,
};

export default Switch;
