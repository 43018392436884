import React from 'react'
import { Field } from '../../components/Form'
import { Box, Card } from '@builtbypixel/plasma'
import EditView from '../../components/EditView'
import setup from './setup'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

const EditForm = () => {
   const { type } = useParams()

   const useValidation = () => {
      if (type === 'create') {
         return yup.object().shape({
            first_name: yup.string().required('Required'),
            last_name: yup.string().required('Required'),
            email: yup.string().email().required('Required'),
            password: yup.string().min(8).required('Required'),
            password_confirm: yup
               .string()
               .required('Required')
               .oneOf([yup.ref('password')], "Passwords don't match")
         })
      }
      if (type === 'edit') {
         return yup.object().shape({
            first_name: yup.string().required('Required'),
            last_name: yup.string().required('Required'),
            email: yup.string().email().required('Required'),
            password: yup.string('Required'),
            password_confirm: yup.string().when('password', {
               is: (password) => password && password.length > 0,
               then: yup
                  .string()
                  .required('Field is required')
                  .oneOf([yup.ref('password')], "Passwords don't match")
            })
         })
      }
   }

   return (
      <EditView setup={setup} validationSchema={useValidation()}>
         <Card maxWidth='600px' w='100%' p='20px' margin=' 25px auto'>
            <Field
               auto
               isFullWidth
               name='first_name'
               component='text'
               label='First Name'
               isRequired
               rules={{ required: true }}
            />
            <Field
               auto
               isFullWidth
               name='last_name'
               component='text'
               label='Last Name'
               isRequired
               rules={{ required: true }}
            />
            <Field
               auto
               isFullWidth
               name='email'
               component='text'
               type='email'
               label='Email Address'
               isRequired
               rules={{ required: true }}
            />

            <Box mt='25px'>
               <Field
                  auto
                  isFullWidth
                  name='password'
                  component='text'
                  label={type === 'create' ? 'Password' : 'Change Password'}
                  isRequired={type === 'create'}
                  rules={{ required: true }}
               />
               <Field
                  auto
                  isFullWidth
                  name='password_confirm'
                  component='text'
                  label={
                     type === 'create'
                        ? 'Confirm Password'
                        : 'Confirm New Password'
                  }
                  isRequired={type === 'create'}
                  rules={{ required: true }}
               />
            </Box>
         </Card>
      </EditView>
   )
}

export default EditForm
