/** @jsx jsx */
import { jsx } from '@emotion/core'
import useBadgeStyle from './styles'
import PropTypes from 'prop-types'
import Box from '../Box'
import { forwardRef } from 'react'

const Badge = forwardRef(
  ({ variantColor = 'primary', variant = 'outline', ...props }, ref) => {
    const badgeStyleProps = useBadgeStyle({ color: variantColor, variant })

    return (
      <Box
        ref={ref}
        display='inline-block'
        px='5px'
        py='2px'
        fontSize='sm'
        borderRadius='4px'
        fontWeight='normal'
        whiteSpace='nowrap'
        verticalAlign='middle'
        {...badgeStyleProps}
        {...props}
      />
    )
  }
)

Badge.displayName = 'Badge'

Badge.propTypes = {
  /**
   * The type or status of the alert
   */
  variant: PropTypes.oneOf(['outline', 'subtle', 'solid', 'plain']),
  /**
   * The variant - accepts any theme colour
   */
  variantColor: PropTypes.oneOfType(
    ['green', 'red', 'primary', 'orange'],
    PropTypes.string
  )
}

Badge.defaultProps = {
  variant: 'subtle',
  variantColor: 'primary'
}

export default Badge
