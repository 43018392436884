import { atom } from 'recoil'

export const globalAtom = atom({
   key: 'globalState',
   default: {
      config: null,
      routes: null,
      links: [],
      user: null,
      authLoading: true,
      formErrors: null
   }
})

export const menuAtom = atom({
   key: 'menuState',
   default: false
})

export const tableAtom = atom({
   key: 'tableState',
   default: {
      selected: [],
      selectedRows: [],
      params: {
         filters: null
      }
   }
})
