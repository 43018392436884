import colours from './colours'
import sizes, { baseSizes } from './sizes'
import typography from './typography'

const space = baseSizes

const shadows = {
  sm: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  md: '0 0px 10px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  xl:
    '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  outline: '0 0 0 3px rgba(66, 153, 225, 0.6)',
  inner: 'inset 0 -1px 2px  rgba(0,0,0,0.4)',
  none: 'none',
  inputFocus: '0 0 0 0px #92c3ed',
  buttonFocus: 'rgba(66, 153, 225, 0.6) 0px 0px 0px 3px',
  card: '0px 1px 2px 0 rgba(0, 0, 0, 0.15)',
  dropdown: 'rgba(0,0,0,.05) 0 10px 10px -5px',
  popover: 'rgba(0,0,0,.05) 0 10px 10px -5px',
  toast: 'rgba(0,0,0,.3) 0 0px 10px -2px',
  modal: '0px 2px 4px rgba(0,0,0,0.1)'
}

const breakpoints = ['480px', '1024px', '1224px', '1800px']
// aliases
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const zIndices = {
  hide: -1,
  auto: 'auto',
  base: 0,
  docked: 10,
  dropdown: 1000,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800
}

const radii = {
  none: '0',
  sm: '0.125rem',
  md: '0.2rem',
  lg: '0.4rem',
  xl: '10px',
  full: '9999px',
  card: '10px',
  tabs: '0.25rem',
  dropdown: '0.7rem',
  popover: '0.7rem',
  toast: '0.7rem',
  button: '0.4rem',
  alert: '0.4rem',
  modal: '10px',
  inputBorderRadiusLg: '0.4rem',
  inputBorderRadiusMd: '0.32rem',
  inputBorderRadiusSm: '0.4rem'
}

const opacity = {
  0: '0',
  '20%': '0.2',
  '40%': '0.4',
  '60%': '0.6',
  '80%': '0.8',
  '100%': '1'
}

const borders = {
  none: 0,
  '1px': '1px solid',
  '2px': '2px solid',
  '4px': '4px solid',
  control: '1px solid',
  input: '1px solid',
  button: '1px solid'
}

// const borderWidths = {
//   xl: "2rem",
//   lg: "1rem",
//   md: "0.5rem",
//   sm: "0.25rem",
//   xs: "0.125rem",
//   "2xs": "0.0625rem",
//   none: 0
// };

const theme = {
  breakpoints,
  zIndices,
  radii,
  opacity,
  borders,
  colors: { ...colours },
  ...typography,
  sizes,
  shadows,
  space
}

export default theme
