/** @jsx jsx */
import { jsx, Global, css } from '@emotion/core'
import preflight from './preflight'

const defaultConfig = (theme) => ({
  color: theme.colors.global.text,
  bg: theme.colors.global.bg,
  borderColor: theme.colors.global.border,
  placeholderColor: theme.colors.inputs.placeholderColour
})

const CSSReset = ({ config }) => {
  const configCSS = (theme) => {
    const _defaultConfig = defaultConfig(theme)

    const _config = config
      ? config(theme, _defaultConfig)
      : defaultConfig(theme)

    const { color, bg, borderColor, placeholderColor } = _config

    return css`
      html {
        line-height: 1.5;
        color: ${color};
        background-color: ${bg};
        font-size: 14px;
        min-height: 100%;
        height: 100%;
      }

      *,
      *::before,
      *::after {
        border-width: 0;
        border-style: solid;
        border-color: ${borderColor};
      }
      input:-ms-input-placeholder,
      textarea:-ms-input-placeholder {
        color: ${placeholderColor};
      }
      input::-ms-input-placeholder,
      textarea::-ms-input-placeholder {
        color: ${placeholderColor};
      }
      input::placeholder,
      textarea::placeholder {
        color: ${placeholderColor};
      }
    `
  }

  return (
    <Global styles={(theme) => css([preflight(theme), configCSS(theme)])} />
  )
}

export default CSSReset
