import React, {
   useCallback,
   memo,
   cloneElement,
   Children,
   isValidElement,
   useState
} from 'react'
import { use100vh } from 'react-div-100vh'
import { Form } from '../../components/Form'
import {
   Flex,
   Button,
   Text,
   Box,
   Skeleton,
   Stack,
   IconButton,
   ScrollContainer,
   useColorMode
} from '@builtbypixel/plasma'
import useSWR from 'swr'
import { useGet, useHttp } from '../../hooks'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { useParams, useHistory } from 'react-router-dom'
import InfoBar from './InfoBar'
import DeveloperTools from './DeveloperTools'
import MoreOptions from './MoreOptions'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from './ErrorMessage'
import SuccessMessage from './SuccessMessage'
import { formAtom } from '../../state/form'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { RiCodeSSlashLine } from 'react-icons/ri'

const SubmitButton = memo(({ setup }) => {
   const params = useParams()
   const { loading } = useRecoilValue(formAtom)
   const isEdit = params.type === 'edit'
   const { submit } = useFormContext()

   return (
      <Button
         variantColor='success'
         color='white'
         onClick={() => submit()}
         isLoading={loading}
         loadingText='Submitting '
      >
         {isEdit ? `Save ${setup.singular}` : `Submit ${setup.singular}`}
      </Button>
   )
})

const FormColumn = memo((props) => {
   const { isEdit, data, children } = props
   const height = use100vh()
   const { colorMode } = useColorMode()

   return (
      <ScrollContainer>
         <Flex
            w='100%'
            minHeight={`calc(${height}px - 50px)`}
            pb='100px'
            flex={1}
            align='center'
            direction='column'
            bg={colorMode === 'light' ? '#f6f6f7' : 'rgba(0,0,0,0.2)'}
         >
            {isEdit ? (
               data ? (
                  children
               ) : (
                  <Box w='90%' h='100%' maxWidth={600} mt={10}>
                     <Skeleton variant='rect' h='100px' />
                     <Skeleton variant='rect' />
                     <Skeleton h='30px' />
                     <Skeleton />
                     <Skeleton variant='rect' />
                     <Skeleton h='30px' />
                  </Box>
               )
            ) : (
               Children.map(children, (child) => {
                  if (isValidElement(child)) {
                     return cloneElement(child, props)
                  }
                  return child
               })
            )}
         </Flex>
      </ScrollContainer>
   )
})

const EditView = memo((props) => {
   const height = use100vh()
   const { children, setup, isFullWidth, noPadding, validationSchema } = props
   const params = useParams()
   const Http = useHttp()
   const isEdit = params.type === 'edit'
   const isCreate = params.type === 'create'
   const [devTools, setDevTools] = useState(false)

   const history = useHistory()
   const setFormState = useSetRecoilState(formAtom)

   const { data } = useSWR(
      params.type === 'edit' ? `${setup.endpoint}/${params.id}` : null,
      useGet
   )

   React.useState(() => {
      setFormState((old) => ({ ...old, errors: null, success: false }))
   }, [])

   /* eslint-disable */
   const onSubmit = useCallback((values) => {
      setFormState((old) => ({ ...old, loading: true }))
      if (isEdit) {
         Http.put(`${setup.endpoint}/${params.id}`, values)
            .then((res) => {
               setFormState((old) => ({
                  ...old,
                  loading: false,
                  errors: null,
                  success: true
               }))
            })
            .catch((err) => {
               setFormState((old) => ({
                  ...old,
                  loading: false,
                  errors: err,
                  success: false
               }))
            })
      }
      if (isCreate) {
         Http.post(setup.endpoint, values)
            .then((res) => {
               setFormState((old) => ({
                  ...old,
                  loading: false,
                  errors: null,
                  success: true
               }))
               history.push(`/${setup.model}`)
            })
            .catch((err) => {
               setFormState((old) => ({
                  ...old,
                  loading: false,
                  errors: err,
                  success: false
               }))
            })
      }
   }, [])

   return (
      <Form
         data={data && data.data}
         onSubmit={onSubmit}
         validationSchema={validationSchema}
      >
         <Flex
            w={noPadding ? 'calc(100% + 40px)' : '100%'}
            bg='global.elementBgLight'
            h={height}
            overflow='hidden'
            align='center'
            direction='column'
            m={noPadding ? '-20px' : '0px'}
         >
            <Flex
               w='100%'
               align='center'
               px={2}
               h={50}
               flexShrink={0}
               flexGrow={0}
               borderBottom='1px'
               borderColor='global.borderColour'
            >
               <IconButton
                  variant='ghost'
                  rounded='full'
                  mr={2}
                  onClick={() => history.goBack()}
               >
                  <MdKeyboardArrowLeft fontSize='30px' />
               </IconButton>

               <Text
                  as='h1'
                  fontSize='lg'
                  fontWeight='semibold'
                  display={{ xs: 'none', md: 'inline-block' }}
               >
                  {isEdit ? 'Edit' : 'Create'} {setup.singular}
               </Text>
               <Stack ml='auto' isInline spacing='10px' mr='10px'>
                  <MoreOptions setup={setup} />
                  <SubmitButton setup={setup} />
                  {process.env.REACT_APP_ENVIRONMENT === 'development' && (
                     <IconButton
                        onClick={() => setDevTools(!devTools)}
                        rounded='lg'
                        fontSize='16px'
                        mr='0px'
                     >
                        <RiCodeSSlashLine />
                     </IconButton>
                  )}
               </Stack>
            </Flex>

            <ErrorMessage />
            <SuccessMessage />

            <Flex w='100%' h='100%' flex={1}>
               <FormColumn
                  data={data}
                  isEdit={isEdit}
                  children={children}
                  isFullWidth={isFullWidth}
               />
               <InfoBar />

               {process.env.REACT_APP_ENVIRONMENT === 'development' && (
                  <Flex
                     direction='column'
                     w='100%'
                     h='100%'
                     overflowY='scroll'
                     maxWidth='350px'
                     flexGrow={0}
                     bg='#1a1a1e'
                     position='fixed'
                     right={0}
                     top={0}
                     willChange='transform'
                     transition='all 0.3s ease'
                     transform={
                        devTools ? 'translateX(0%)' : 'translateX(100%)'
                     }
                  >
                     <DeveloperTools
                        setDevTools={setDevTools}
                        devTools={devTools}
                     />
                  </Flex>
               )}
            </Flex>
         </Flex>
      </Form>
   )
})
export default EditView
