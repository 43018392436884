/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect, memo, useState, Fragment } from 'react'
import {
  useTable,
  usePagination,
  useSortBy,
  useResizeColumns,
  useRowSelect,
  useExpanded
} from 'react-table'
import PseudoBox from '../PseudoBox'
import Box from '../Box'
import Flex from '../Flex'
import Icon from '../Icon'
import Input from '../Input'
import Text from '../Text'
import Button from '../Button'
import Select from '../NativeSelect'
import Checkbox from '../Checkbox'
import IconButton from '../IconButton'
import Grid from '../Grid'
import Spinner from '../Spinner'
import { BsArrowsExpand, BsArrowsCollapse } from 'react-icons/bs'
import { MdErrorOutline } from 'react-icons/md'
import { HiChevronDown, HiChevronRight } from 'react-icons/hi'

import {
  useTableStyles,
  tableCellStyles,
  tableHeadCellStyles,
  tableRowStyles
} from './styles'
import { FaSortAmountDownAlt, FaSortAmountDown } from 'react-icons/fa'

const LoaderComponent = () => (
  <Flex
    p={5}
    position='absolute'
    top='0px'
    bg='global.elementBg'
    left={0}
    w='100%'
    h='100%'
    justify='center'
    align='center'
    zIndex={100}
    opacity={0.6}
  >
    <Spinner color='primary' size='30px' />
  </Flex>
)

const ErrorComponent = memo(() => (
  <tbody>
    <tr>
      <td>
        <Flex
          p={5}
          position='absolute'
          top='40px'
          bg='global.elementBg'
          left={0}
          w='100%'
          h='100%'
          justify='center'
          align='flex-start'
          zIndex={100}
          opacity={1}
        >
          <Flex align='center'>
            <Icon mr='10px' fontSize='22px' color='error'>
              <MdErrorOutline />
            </Icon>{' '}
            <Text fontSize='18px'>Error fetching data</Text>
          </Flex>
        </Flex>
      </td>
    </tr>
  </tbody>
))

const HeaderColumn = memo(
  ({ column, isBordered, size, isSorted, isSortedDesc }) => (
    <PseudoBox
      {...tableHeadCellStyles({ isBordered, size, isSorted }, column.cellProps)}
      {...column.getHeaderProps()}
      _hover={{ cursor: 'pointer ' }}
      style={{ position: 'sticky', zIndex: 10 }}
      w={'100%'}
    >
      <Flex
        align='center'
        justify='flex-start'
        w='100%'
        textAlign='left'
        {...column.getSortByToggleProps()}
      >
        {column.render('Header')}

        <Box ml='auto'>
          {isSorted ? (
            isSortedDesc ? (
              <FaSortAmountDownAlt />
            ) : (
              <FaSortAmountDown />
            )
          ) : (
            ''
          )}
        </Box>
      </Flex>
    </PseudoBox>
  )
)

const TableCell = memo(({ cell, size }) => (
  <PseudoBox
    {...tableCellStyles({ size }, cell.column.cellProps)}
    {...cell.getCellProps()}
    w={'100%'}
  >
    {cell.render('Cell')}
  </PseudoBox>
))

const TableRow = ({
  row,
  size,
  onRowSelect,
  isExpandable,
  toggleRowExpanded
}) => {
  return (
    <PseudoBox
      display='contents'
      {...row.getRowProps()}
      {...tableRowStyles({ isSelected: row.isSelected })}
      _hover={{ bg: !row.isSelected && 'tables.rowHoverBg' }}
    >
      <PseudoBox
        {...tableCellStyles({ size })}
        textOverflow='normal'
        px={!isExpandable && '0px'}
      >
        {isExpandable && row.canExpand && (
          <IconButton
            rounded='md'
            variant='link'
            fontSize='1.4em'
            onClick={() => toggleRowExpanded(row.id)}
          >
            {row.isExpanded ? <HiChevronDown /> : <HiChevronRight />}
          </IconButton>
        )}
      </PseudoBox>

      <PseudoBox {...tableCellStyles({ size })}>
        <IndeterminateCheckbox
          onRowSelect={onRowSelect}
          {...row.getToggleRowSelectedProps()}
        />
      </PseudoBox>

      {row.cells.map((cell, i) => {
        return <TableCell key={`cell-${i}`} cell={cell} size={size} />
      })}
    </PseudoBox>
  )
}

const IndeterminateCheckbox = memo(
  ({ indeterminate, isChecked, onRowSelect, ...rest }) => {
    return (
      <Flex
        align='center'
        justify='center'
        w='100%'
        h='100%'
        opacity={!onRowSelect && 0.7}
      >
        <Checkbox
          indeterminate={indeterminate}
          isDisabled={!onRowSelect && true}
          _hover={{ borderColor: 'red' }}
          {...rest}
        />
      </Flex>
    )
  }
)

const Table = (props) => {
  const {
    columns,
    data,
    showHeader,
    loading,
    onChangePageSize,
    onChangePage,
    onRowSelect = null,
    onSortSelect,
    manualPagination = true,
    hidePagination = false,
    accessor,
    error,
    isExpandable,
    showTopPagination
  } = props

  // const defaultColumn = useMemo(
  //   () => ({
  //     minWidth: 200
  //   }),
  //   []
  // )

  const {
    getTableProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    selectedFlatRows,
    nextPage,
    previousPage,
    setPageSize,
    getToggleRowSelectedProps,
    getToggleAllRowsSelectedProps,
    canPreviousPage,
    canNextPage,
    isAllRowsExpanded,
    toggleAllRowsExpanded,
    toggleRowExpanded,
    state: { pageSize, selectedRowIds, sortBy }
  } = useTable(
    {
      columns,
      manualRowSelectedKey: 'isSelected',
      manualExpandedKey: 'isExpanded',
      data: data?.data,
      manualSortBy: true,
      manualPagination: manualPagination,
      initialState: { selectedFlatRows: [] }
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns
  )

  /* eslint-disable */
  useEffect(() => {
    if (onRowSelect) {
      onRowSelect(selectedFlatRows)
    }
  }, [selectedRowIds])

  useEffect(() => {
    if (onSortSelect) {
      onSortSelect(sortBy)
    }
  }, [sortBy])

  const rowMap = manualPagination ? rows : page

  // Render the UI for your table
  return (
    <Fragment>
      {!hidePagination && showTopPagination && (
        <Pagination
          meta={data.meta}
          onChangePage={onChangePage}
          manualPagination={manualPagination}
          canPreviousPage={canPreviousPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onChangePageSize={onChangePageSize}
          canNextPage={canNextPage}
        />
      )}
      <Grid
        {...getTableProps()}
        {...useTableStyles(props)}
        templateColumns={`fit-content(0px) fit-content(0px) repeat(${columns.length}, minmax(100px, auto))`}
      >
        {showHeader &&
          headerGroups?.map((headerGroup, i) => (
            <PseudoBox
              key={`header-${i}`}
              {...headerGroup.getHeaderGroupProps()}
              display='contents'
            >
              <PseudoBox
                {...tableHeadCellStyles({
                  isBordered: props.isBordered,
                  size: props.size,
                  isSorted: false
                })}
                position='sticky'
                top='0px'
                zIndex={10}
                px={!isExpandable && '0px'}
              >
                {isExpandable && (
                  <IconButton
                    variant='link'
                    fontSize='1.2em'
                    onClick={() => toggleAllRowsExpanded()}
                  >
                    {isAllRowsExpanded ? (
                      <BsArrowsCollapse />
                    ) : (
                      <BsArrowsExpand />
                    )}
                  </IconButton>
                )}
              </PseudoBox>

              <PseudoBox
                {...tableHeadCellStyles({
                  isBordered: props.isBordered,
                  size: props.size,
                  isSorted: false
                })}
                position='sticky'
                top='0px'
                zIndex={10}
              >
                <IndeterminateCheckbox
                  onRowSelect={onRowSelect}
                  {...getToggleAllRowsSelectedProps()}
                />
              </PseudoBox>

              {headerGroup.headers?.map((column, i) => (
                <HeaderColumn
                  key={`headerCol-${i}`}
                  column={column}
                  isBordered={props.isBordered}
                  size={props.size}
                  isSorted={column.isSorted}
                  isSortedDesc={column.isSortedDesc}
                />
              ))}
            </PseudoBox>
          ))}
        {loading && <LoaderComponent />}
        {error && <ErrorComponent />}

        {rowMap &&
          rowMap.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow
                key={`row-${i}`}
                row={row}
                accessor={accessor}
                isSelected={row.isSelected}
                size={props.size}
                getToggleRowSelectedProps={getToggleRowSelectedProps}
                onRowSelect={onRowSelect}
                isExpandable={isExpandable}
                toggleRowExpanded={toggleRowExpanded}
              />
            )
          })}
      </Grid>

      {!hidePagination && (
        <Pagination
          meta={data.meta}
          onChangePage={onChangePage}
          manualPagination={manualPagination}
          canPreviousPage={canPreviousPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onChangePageSize={onChangePageSize}
          canNextPage={canNextPage}
        />
      )}
    </Fragment>
  )
}

Table.defaultProps = {
  showHeader: true,
  isBordered: true,
  size: 'md',
  onRowSelect: null,
  showTopPagination: false
}

const Pagination = ({
  meta,
  onChangePage,
  manualPagination,
  canPreviousPage,
  pageSize,
  setPageSize,
  onChangePageSize,
  canNextPage
}) => {
  const [pagination, setPagination] = useState(meta ? meta.current_page : 1)

  useEffect(() => {
    if (meta && meta.current_page) {
      setPagination(meta.current_page)
    }
  }, [meta])

  return (
    <Flex
      justify='center'
      align='center'
      p={2}
      mt='auto'
      borderTop='1px'
      borderColor='global.borderColour'
      position='sticky'
      bottom={0}
      bg='global.elementBg'
    >
      <Button
        onClick={() => {
          previousPage()
          onChangePage(onChangePage ? meta?.current_page - 1 : null)
        }}
        disabled={
          manualPagination
            ? meta?.current_page === meta?.from
            : !canPreviousPage
        }
        variant='ghost'
        mr='45px'
        size='sm'
      >
        Previous
      </Button>{' '}
      <Flex align='center'>
        <Box as='span' mr='10px' fontSize='md'>
          Page{' '}
        </Box>
        <Input
          type='number'
          value={pagination}
          min={1}
          size='sm'
          max={meta?.last_page}
          defaultValue={meta?.current_page}
          onBlur={(e) => {
            let v = e.target.value
            if (e.target.value > meta?.last_page) {
              v = meta?.last_page
              setPagination(meta?.last_page)
            }
            if (e.target.value < 1) {
              v = 1
              setPagination(1)
            }
            onChangePage(onChangePage ? v : null)
          }}
          w={70}
          onChange={(e) => setPagination(e.target.value)}
        />
        <Box ml='10px' fontSize='md'>
          of {meta?.last_page}
        </Box>
      </Flex>
      <Select
        ml='10px'
        maxWidth='150px'
        size='sm'
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value))
          onChangePageSize(e.target.value)
        }}
      >
        {[10, 20, 30, 40, 50, 100, 200].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </Select>
      <Button
        onClick={() => {
          nextPage()
          onChangePage(onChangePage ? meta?.current_page + 1 : null)
        }}
        disabled={
          manualPagination
            ? meta?.current_page === meta?.last_page
            : !canNextPage
        }
        size='sm'
        variant='ghost'
        ml='45px'
      >
        Next
      </Button>{' '}
    </Flex>
  )
}

export default Table
