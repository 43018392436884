import React, { useState, useEffect } from 'react'
import Box from '../Box'
import Flex from '../Flex'
import { HiCheck } from 'react-icons/hi'
import { RiSubtractLine } from 'react-icons/ri'
import { CustomCheckboxContainer, CustomCheckboxInput } from '@reach/checkbox'
import PseudoBox from '../PseudoBox'
import { useTheme } from '../ColourModeProvider'
import { isDarkColor } from '../theme/colors-utils'

function getContainerStyle() {
  return {
    background: 'transparent',
    border: 'none',
    width: 'auto',
    height: 'auto'
  }
}

const sizes = {
  lg: {
    w: '24px',
    h: '24px'
  },
  md: {
    w: '18px',
    h: '18px'
  },
  sm: {
    w: '14px',
    h: '14px'
  }
}

const outlineStyle = ({ checked, indeterminate, isDisabled, theme }) => {
  return {
    bg: isDisabled ? theme.colors.inputs.borderColour : 'transparent',
    borderColor: checked
      ? 'primary'
      : indeterminate
      ? 'primary'
      : theme.colors.inputs.borderColour,
    borderWidth: 2,
    borderRadius: '5px',
    position: 'relative',
    mr: 1,
    opacity: isDisabled ? 0.3 : 1,
    _hover: {
      borderColor: isDisabled
        ? theme.colors.inputs.borderColour
        : !checked && 'gray.400',
      cursor: isDisabled ? 'not-allowed' : 'pointer'
    }
  }
}

const useCheckStyle = (checked, indeterminate, theme) => {
  return {
    display: 'block',
    position: 'absolute',
    w: '100%',
    h: '100%',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%) scaleX(${
      !!checked ? 1 : indeterminate ? 1 : 0
    }) scaleY(${checked === true ? 1 : indeterminate ? 1 : 0})`,
    transition: 'transform 120ms ease-out, background 120ms ease-out',
    zIndex: 1,
    color: isDarkColor(theme.colors.primary) ? 'white' : 'blackAlpha.900',
    bg: checked === true ? 'primary' : indeterminate ? 'primary' : 'transparent'
  }
}

const Checkbox = (props) => {
  const {
    size,
    onChange,
    checked,
    defaultChecked,
    isDisabled,
    name,
    value,
    label,
    indeterminate
  } = props

  const [checkedState, setChecked] = useState(defaultChecked)
  const theme = useTheme()

  const handleChange = (chx) => {
    setChecked(!checkedState)

    if (onChange) {
      onChange(chx)
    }
  }

  useEffect(() => {
    setChecked(checked)
  }, [checked])

  return (
    <Flex
      as='label'
      align='center'
      justify='flex-start'
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
    >
      <CustomCheckboxContainer
        checked={checked ? checked : indeterminate ? true : checkedState}
        disabled={isDisabled}
        onChange={(event) => handleChange(event)}
        style={getContainerStyle()}
      >
        <PseudoBox
          {...outlineStyle({
            checked: checkedState,
            indeterminate,
            isDisabled,
            theme
          })}
          {...sizes[size]}
        >
          <CustomCheckboxInput
            checked={checked}
            name={name}
            value={value}
            style={{ display: 'none' }}
            disabled={isDisabled}
          />
          <PseudoBox {...useCheckStyle(checkedState, indeterminate, theme)}>
            {indeterminate === true ? (
              <RiSubtractLine
                style={{
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer'
                }}
              />
            ) : (
              <HiCheck
                aria-hidden
                style={{
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer',
                  size: '24px'
                }}
              />
            )}
          </PseudoBox>
        </PseudoBox>
      </CustomCheckboxContainer>
      <Box fontSize={size} as='span' opacity={isDisabled ? 0.4 : 1}>
        {label}
      </Box>
    </Flex>
  )
}

Checkbox.defaultProps = {
  size: 'md',
  checked: false,
  defaultChecked: false,
  isDisabled: false,
  name: '',
  value: ''
}

export default Checkbox
