import React, { useCallback, useEffect, useState, memo } from 'react'
import { useHttp } from '../../hooks'
import { FiTrash, FiPlus } from 'react-icons/fi'
import { BiSearch } from 'react-icons/bi'
import TableLoader from '../common/TableLoader'
import { useRecoilState } from 'recoil'
import { tableAtom } from '../../state/global'
import {
   Table,
   Box,
   Stack,
   Flex,
   Button,
   useToast,
   PopConfirm,
   Input,
   DatePicker,
   Select,
   ScrollContainer,
   Text
} from '@builtbypixel/plasma'
import LinkButton from '../LinkButton'

const MemoTable = memo(
   ({
      setup,
      columns,
      data = null,
      error,
      loading,
      onChangePageSize,
      onChangePage,
      onRowSelect,
      onSortSelect,
      ...rest
   }) => {
      return (
         !error && (
            <Box bg='global.elementBg' h='100%' maxHeight='100%'>
               <ScrollContainer>
                  {!loading ? (
                     data.data?.length ? (
                        <Table
                           accessor={setup.accessor}
                           columns={columns}
                           data={data}
                           loading={loading}
                           onChangePageSize={onChangePageSize}
                           onChangePage={onChangePage}
                           onRowSelect={onRowSelect}
                           onSortSelect={onSortSelect}
                           size='sm'
                           {...rest}
                        />
                     ) : (
                        <Flex
                           w='100%'
                           h='500px'
                           align='center'
                           justify='center'
                        >
                           <Text fontSize='1.8rem'>No results found</Text>
                        </Flex>
                     )
                  ) : (
                     <Box p='20px'>
                        <TableLoader />
                     </Box>
                  )}
               </ScrollContainer>
            </Box>
         )
      )
   }
)

MemoTable.displayName = 'MemoTable'

const TableComp = ({
   setup,
   columns,
   extraMenu = null,
   filters,
   handleRowSelection = null,
   ...rest
}) => {
   const [tableState, setTableState] = useRecoilState(tableAtom)

   const Http = useHttp()
   const toast = useToast()
   const ExtraComponent = extraMenu
   const [error, setError] = useState(false)
   const [loading, setLoading] = useState(true)
   const [data, setData] = useState({
      data: []
   })

   const [selected, setSelected] = useState([])

   const setFilter = useCallback(
      (name, value) => {
         const _filters = { ...tableState.params.filters }
         _filters[name] = value
         setTableState((old) => ({
            ...old,
            params: {
               ...old.params,
               filters: _filters || null
            }
         }))
      },
      [setTableState, tableState.params.filters]
   )

   const getField = (field) => {
      switch (field.component) {
         case 'date':
            return (
               <DatePicker
                  {...field}
                  variant='unstyled'
                  value={
                     tableState.params?.filters
                        ? tableState.params?.filters[field.name]
                        : null
                  }
                  onChange={(date) => setFilter(field.name, date)}
               />
            )
         case 'select':
            return (
               <Select
                  value={
                     tableState.params?.filters
                        ? tableState.params?.filters[field.name]
                        : null
                  }
                  options={field.options}
                  onChange={(value) => setFilter(field.name, value)}
                  {...field}
               />
            )
         case 'text':
            return (
               <Input
                  {...field}
                  value={
                     tableState.params?.filters
                        ? tableState.params?.filters[field.name]
                        : null
                  }
                  onChange={(e) => setFilter(field.name, e.target.value)}
               />
            )
         default:
            return null
      }
   }

   /* eslint-disable  */
   const fetchData = () => {
      setLoading(true)

      Http.get(setup.endpoint, { params: tableState.params })
         .then((res) => {
            setData(res.data)
            setLoading(false)
            setError(false)
         })
         .catch(() => {
            toast({
               title: 'Error fetching data',
               message: 'Please contact support',
               status: 'error',
               position: 'top',
               variant: 'plain'
            })
            setLoading(false)
            setError(true)
         })
   }

   useEffect(() => {
      fetchData()
   }, [
      tableState.params.pagination,
      tableState.params.page,
      tableState.params.sort,
      tableState.params.order
   ])

   const onChangePageSize = useCallback(
      (size) => {
         setTableState((old) => ({
            ...old,
            params: { ...old.params, pagination: size }
         }))
      },
      [setTableState]
   )

   const onChangePage = useCallback(
      (page) => {
         setTableState((old) => ({
            ...old,
            params: { ...old.params, page }
         }))
      },
      [setTableState]
   )

   const handleRowSelect = useCallback(
      (rows) => {
         setSelected(rows.length !== 0 ? rows.map((d) => d.original.id) : [])
         setTableState((old) => ({
            ...old,
            selected: rows.length !== 0 ? rows.map((d) => d.original.id) : [],
            selectedRows: rows.length !== 0 ? rows.map((d) => d.original) : []
         }))
         if (handleRowSelection) {
            handleRowSelection(rows)
         }
      },
      [setTableState]
   )

   const onSortSelect = useCallback(
      (filter) => {
         if (filter[0]) {
            setTableState((old) => ({
               ...old,
               params: {
                  ...old.params,
                  sort: filter[0].id,
                  order: filter[0].desc ? 'desc' : 'asc'
               }
            }))
         }
      },
      [setTableState]
   )

   const onDelete = () => {
      setLoading(true)
      return Http.delete(`${setup.endpoint}`, { data: { id: [...selected] } })
         .then(() => {
            fetchData()
         })
         .catch(() => {
            toast({
               title: 'Error deleting data',
               message: 'Please contact support',
               status: 'error',
               position: 'top',
               variant: 'plain'
            })
            setLoading(false)
         })
   }

   /* eslint-enable */

   const onSearch = useCallback(
      (value) => {
         // setParams((curr) => ({ ...curr, search: value }))
         setTableState((old) => ({
            ...old,
            params: { ...old.params, search: value }
         }))
      },
      [setTableState]
   )

   return (
      <Flex height='auto' direction='column'>
         <Box position='sticky' top={0} zIndex={500}>
            <Flex
               w='100%'
               bg='global.elementBg'
               h='60px'
               borderBottom='1px'
               align='center'
               px={5}
               borderColor='global.borderColour'
               position='relative'
               zIndex={1000}
            >
               <Text as='h1' fontWeight='semibold' fontSize='18px'>
                  {setup.title}
               </Text>

               <Flex ml='auto' align='center'>
                  {extraMenu && (
                     <Box mr='10px'>
                        <ExtraComponent />
                     </Box>
                  )}

                  {setup.canAddNew === true && (
                     <Box>
                        <LinkButton to={`${setup.model}/create`}>
                           <Button variantColor='primary' leftIcon={<FiPlus />}>
                              Create New
                           </Button>
                        </LinkButton>
                     </Box>
                  )}
               </Flex>
            </Flex>
            <Flex
               borderBottom='1px'
               borderColor='global.borderColour'
               align='center'
               bg='global.elementBg'
            >
               <Stack
                  isInline
                  py='8px'
                  align='center'
                  spacing='3px'
                  // minHeight='46px'
                  pl='8px'
               >
                  {setup.canSearch && (
                     <Box>
                        <Input
                           minWidth={240}
                           placeholder='Search...'
                           value={tableState.params.search}
                           px='15px'
                           onChange={(e) => onSearch(e.target.value)}
                        />
                     </Box>
                  )}
                  {filters && (
                     <Stack spacing='3px' isInline>
                        {filters.map((filter, i) => (
                           <Flex
                              key={`filter-${i}`}
                              maxWidth='190px'
                              align='center'
                           >
                              {getField(filter)}
                           </Flex>
                        ))}
                     </Stack>
                  )}
                  {setup.canSearch && (
                     <Box>
                        <Button
                           variant='outline'
                           leftIcon={<BiSearch />}
                           isLoading={data && loading}
                           isDisabled={loading}
                           loadingText='Searching...'
                           onClick={() => fetchData()}
                        >
                           Search
                        </Button>
                     </Box>
                  )}

                  {(setup.canSelect === undefined || setup.canSelect) && (
                     <Box fontWeight='medium' fontSize='sm' pl='10px'>
                        {selected.length} items selected
                     </Box>
                  )}

                  <Box>
                     {setup.canDelete && selected.length !== 0 && (
                        <PopConfirm
                           okText='Delete'
                           onConfirm={() => onDelete()}
                           okButtonProps={{ variantColor: 'error' }}
                           cancelButtonProps={{ variantColor: 'gray' }}
                           title='Are you sure you want to delete these entries?  This cannot be undone.'
                        >
                           <Button
                              leftIcon={<FiTrash />}
                              variant='ghost'
                              size='sm'
                              variantColor='error'
                           >
                              Delete
                           </Button>
                        </PopConfirm>
                     )}
                  </Box>
               </Stack>
               <Box ml='auto' pr='15px'>
                  {extraMenu && <ExtraComponent />}
               </Box>
            </Flex>
         </Box>

         <MemoTable
            accessor={setup.accessor}
            columns={columns}
            data={setup.dataKey ? data?.data[setup.dataKey] : data}
            setup={setup}
            loading={loading}
            error={error}
            onChangePageSize={onChangePageSize}
            onChangePage={onChangePage}
            onRowSelect={
               setup.canSelect === undefined
                  ? handleRowSelect
                  : setup.canSelect
                  ? handleRowSelect
                  : null
            }
            onSortSelect={onSortSelect}
            size='sm'
            {...rest}
         />
      </Flex>
   )
}

TableComp.displayName = 'ListView'

export default TableComp
