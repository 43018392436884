/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import Icon from '../Icon'
import Spinner from '../Spinner'
import useButtonStyle from './styles'
import PseudoBox from '../PseudoBox'
import Box from '../Box'

const ButtonIcon = ({ icon, ...props }) => {
  if (typeof icon === 'string') {
    return (
      <Icon
        focusable='false'
        aria-hidden='true'
        name={icon}
        color='currentColor'
        {...props}
      />
    )
  }
  return (
    <Box
      data-custom-icon
      focusable='false'
      aria-hidden='true'
      color='currentColor'
      fontSize='1.2em'
      {...props}
    >
      {icon}
    </Box>
  )
}

const Button = forwardRef(
  (
    {
      isDisabled,
      isLoading,
      isActive,
      isFullWidth,
      children,
      align = 'center',
      as: Comp = 'button',
      variantColor = 'gray',
      leftIcon,
      rightIcon,
      variant = 'solid',
      loadingText,
      iconSpacing = 2,
      type = 'button',
      size = 'md',
      colorMode,
      ...rest
    },
    ref
  ) => {
    const buttonStyleProps = useButtonStyle({
      color: variantColor,
      variant,
      size,
      colorMode,
      align
    })
    const _isDisabled = isDisabled || isLoading

    return (
      <PseudoBox
        disabled={_isDisabled}
        aria-disabled={_isDisabled}
        ref={ref}
        as={Comp}
        type={type}
        borderRadius='button'
        fontWeight='button'
        width={isFullWidth ? 'full' : undefined}
        data-active={isActive ? 'true' : undefined}
        {...buttonStyleProps}
        {...rest}
      >
        {leftIcon && !isLoading && (
          <ButtonIcon ml={-1} mr={iconSpacing} icon={leftIcon} />
        )}
        {isLoading && (
          <Spinner
            position={loadingText ? 'relative' : 'absolute'}
            mr={loadingText ? iconSpacing : 0}
            color='currentColor'
            size={size}
          />
        )}
        {isLoading
          ? loadingText || (
              <Box as='span' opacity='0'>
                {children}
              </Box>
            )
          : children}
        {rightIcon && !isLoading && (
          <ButtonIcon mr={-1} ml={iconSpacing} icon={rightIcon} />
        )}
      </PseudoBox>
    )
  }
)

Button.displayName = 'Button'

Button.propTypes = {
  /**
   * The element type you wish to render
   */
  as: PropTypes.string,
  /**
   * The variant type of the button
   */
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(['solid', 'link', 'ghost', 'outline']),
    PropTypes.string
  ]),
  /**
   * The text alignment of the button
   */
  align: PropTypes.oneOf(['left', 'center', 'right']),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Colour theme of the button - can relate to colours in your theme colour object
   */
  variantColor: PropTypes.oneOfType([
    PropTypes.oneOf(['gray', 'primary', 'red', 'green']),
    PropTypes.string
  ]),
  /**
   * Is disabled
   */
  isDisabled: PropTypes.bool,
  /**
   * Make button fill full width of container
   */
  isFullWidth: PropTypes.bool,
  /**
   * Toggle loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Set text whilst loading
   */
  loadingText: PropTypes.string,
  /**
   * Set the icon spacing
   */
  iconSpacing: PropTypes.number,
  /**
   * Add an icon to the left side of the button
   */
  leftIcon: PropTypes.node,
  /**
   * Add an icon to the right side of the button
   */
  rightIcon: PropTypes.node
}

export default Button
