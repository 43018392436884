/** @jsx jsx */
import { useContext } from "react";
import { jsx } from "@emotion/core";
import Box from "../Box";
import useInputStyle from "../Input/styles";
import { useColorMode } from "../ColourModeProvider";
import { InputGroupContext } from "../InputGroup/context";

const InputAddon = ({ placement = "left", children, ...props }) => {
  const { size, variant } = useContext(InputGroupContext);
  const { colorMode } = useColorMode();
  const bg = { dark: "whiteAlpha.300", light: "gray.100" };
  const _placement = {
    left: {
      mr: "-1px",
      roundedRight: 0,
      borderRightColor: "transparent",
      pr: 3,
    },
    right: {
      order: 1,
      roundedLeft: 0,
      borderLeftColor: "transparent",
    },
  };

  const styleProps = {
    ...useInputStyle({ size, variant }),
    bg:
      variant !== "unstyled" && variant !== "flushed"
        ? bg[colorMode]
        : "transparent",
    flex: "0 0 auto",
    fontWeight: "semibold",
    whiteSpace: "nowrap",
    ..._placement[placement],
  };

  return <Box {...styleProps} children={children} />;
};

const InputLeftAddon = (props) => <InputAddon placement="left" {...props} />;

const InputRightAddon = (props) => <InputAddon placement="right" {...props} />;

export { InputLeftAddon, InputRightAddon, InputAddon };
