/** @jsx jsx */
import { useState, createContext, useContext, createRef } from 'react'
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { useTheme, useColorMode } from '../ColourModeProvider'
import useInputStyle from './styles'
import { isDarkColor } from '../theme/colors-utils'
import { default as UISelect, components } from 'react-select'
import AsyncSelect from 'react-select/async'
import PseudoBox from '../PseudoBox'
import Spinner from '../Spinner'
import Box from '../Box'

const SelectContext = createContext()

const CustomControl = (props) => {
  const { isFocused } = props
  const { variant, size } = useContext(SelectContext)
  const inputStyleProps = useInputStyle({ variant, size })

  const focusProps = () => {
    if (isFocused) {
      return {
        boxShadow: 'buttonFocus'
      }
    }
  }

  return (
    <components.Control {...props}>
      <PseudoBox
        as='button'
        type='button'
        ref={props.innerRef}
        {...inputStyleProps}
        pl='0px'
        pr='5px'
        w='100%'
        disabled={props.isDisabled}
        {...focusProps()}
        maxHeight='none'
        minWidth='100px'
        minHeight='20px'
      >
        {props.children}
      </PseudoBox>
    </components.Control>
  )
}

const CustomLoader = (props) => {
  return (
    <Spinner
      size={props.size === 'lg' ? 'md' : props.size}
      color='gray.400'
      mr='5px'
    />
  )
}

const CustomMenu = (props) => {
  return (
    <components.Menu {...props}>
      <Box
        rounded='popover'
        border='1px'
        boxShadow='popover'
        borderColor='popover.borderColour'
        mt='0px'
        bg='global.elementBgAlt'
        overflow='hidden'
        zIndex={9900}
        position='absolute'
        top={0}
        w='100%'
      >
        {props.children}
      </Box>
    </components.Menu>
  )
}

const Select = (props) => {
  const theme = useTheme()
  const { colorMode } = useColorMode()

  const {
    size,
    variant,
    isFullWidth,
    value,
    onChange,
    labelKey,
    valueKey,
    options,
    isAsync,
    cacheOptions = false,
    loadOptions,
    container
  } = props
  const [selectedOption, setSelectedOption] = useState(null)
  const inputStyleProps = useInputStyle({ variant: 'unstyled', size })

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%'
    }),
    control: (provided) => ({
      ...provided,
      outline: 0,
      border: 0,
      boxShadow: 'none',
      borderRadius: 0,
      background: 'transparent',
      minHeight: size === 'sm' ? '25px' : '30px'
    }),
    input: (provided) => ({
      ...provided,
      ...inputStyleProps,
      color: theme.colors.global.text,
      type: 'button'
    }),
    menu: (provided) => ({
      ...provided,
      border: 0,
      boxShadow: 'none',
      background: 'transparent',
      zIndex: 9999
    }),
    multiValue: (base) => ({
      ...base,
      background: 'transparent'
    }),
    multiValueLabel: (base) => ({
      ...base,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      backgroundColor:
        colorMode === 'light' ? theme.colors.gray[700] : theme.colors.gray[600],
      border: theme.colors.gray[900],
      borderRadius: '2px',
      color: colorMode === 'light' ? theme.colors.gray[200] : 'white',
      fontWeight: theme.fontWeights.semibold
    }),
    valueContainer: () => ({
      padding: size === 'sm' ? '0px 4px' : '2px 4px'
    }),
    multiValueRemove: (base) => ({
      ...base,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      color: colorMode === 'light' ? theme.colors.gray[200] : 'white',
      backgroundColor:
        colorMode === 'light' ? theme.colors.gray[800] : theme.colors.gray[700],
      border:
        colorMode === 'light' ? theme.colors.gray[800] : theme.colors.gray[700]
    }),
    dropdownIndicator: (base) => ({
      padding: '3px 5px'
    }),
    singleValue: (base) => ({
      ...base,
      color:
        colorMode === 'dark' ? theme.colors.gray[50] : theme.colors.gray[900]
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected
        ? isDarkColor(theme.colors.primary)
          ? 'white'
          : theme.colors.gray[900]
        : colorMode === 'light'
        ? theme.colors.gray[900]
        : 'white',
      backgroundColor: state.isSelected ? theme.colors.primary : 'transparent',
      fontWeight: state.isSelected ? 700 : 'normal',
      '&:hover': {
        background: !state.isSelected
          ? colorMode === 'light'
            ? theme.colors.gray[100]
            : theme.colors.whiteAlpha[100]
          : theme.colors.primary
      },
      fontSize: size === 'sm' ? '12px' : '13px',
      padding: size === 'sm' ? '5px 8px' : '5px 10px'
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '2px'
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      marginLeft: 'auto'
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 })
  }

  const selectRef = createRef()

  return (
    <SelectContext.Provider value={{ size, variant, isFullWidth, selectRef }}>
      {isAsync ? (
        <AsyncSelect
          defaultValue={selectedOption}
          onChange={onChange ? onChange : setSelectedOption}
          value={value ? value : selectedOption}
          styles={customStyles}
          defaultOptions={options}
          cacheOptions={cacheOptions}
          getOptionLabel={(option) =>
            labelKey ? option[labelKey] : option['label']
          }
          getOptionValue={(option) =>
            valueKey ? option[valueKey] : option['value']
          }
          components={{
            Control: CustomControl,
            LoadingIndicator: CustomLoader,
            Menu: CustomMenu
          }}
          loadOptions={loadOptions}
          menuPortalTarget={
            !container && typeof window !== 'undefined'
              ? document.body
              : container
          }
          {...props}
        />
      ) : (
        <UISelect
          defaultValue={selectedOption}
          onChange={onChange ? onChange : setSelectedOption}
          value={value ? value : selectedOption}
          styles={customStyles}
          options={options}
          getOptionLabel={(option) =>
            labelKey ? option[labelKey] : option['label']
          }
          getOptionValue={(option) =>
            valueKey ? option[valueKey] : option['value']
          }
          components={{
            Control: CustomControl,
            LoadingIndicator: CustomLoader,
            Menu: CustomMenu
          }}
          menuPortalTarget={
            !container && typeof window !== 'undefined'
              ? document.body
              : container
          }
          {...props}
        />
      )}
    </SelectContext.Provider>
  )
}

Select.displayName = 'Select'

Select.defaultProps = {
  variant: 'outline',
  size: 'md',
  isClearable: true,
  menuPlacement: 'auto',
  openMenuOnClick: true
}

Select.propTypes = {
  /**
   * The variant type of the button
   */
  options: PropTypes.array,
  /**
   * The variant of the input
   */
  variant: PropTypes.oneOf(['outline', 'filled', 'flushed']),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  /**
   * Is disabled
   */
  isDisabled: PropTypes.bool,
  /**
   * Is loading
   */
  isLoading: PropTypes.bool,
  /**
   * Support multiple selected options
   */
  isMulti: PropTypes.bool
}

export default Select
