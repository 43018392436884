import React, { useRef, useEffect } from 'react'
import sha256 from 'js-sha256'
import { Box, Spinner, Flex } from '@builtbypixel/plasma'

const Cloudinary = (props) => {
   const {
      handleSelect,
      value,
      max = 5,
      isMultiple = false,
      setOpen,
      showOptions
   } = props

   const container = useRef()

   useEffect(() => {
      async function launch() {
         const timestamp = await ((Date.now() / 1000) | 0).toString()
         const auth = `cloud_name=${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}&timestamp=${timestamp}&username=${process.env.REACT_APP_CLOUDINARY_USERNAME}${process.env.REACT_APP_CLOUDINARY_SECRET}`

         if (container.current) {
            const ml = window.cloudinary.createMediaLibrary(
               {
                  cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
                  api_key: process.env.REACT_APP_CLOUDINARY_KEY,
                  remove_header: true,
                  max_files: max,
                  multiple: isMultiple,
                  username: process.env.REACT_APP_CLOUDINARY_USERNAME,
                  timestamp: timestamp,
                  signature: sha256(auth)
               },
               {
                  insertHandler: (res) => {
                     handleSelect(res)
                     setOpen(false)
                  }
               }
            )

            ml.show(showOptions)
         }
      }
      launch()
   }, [container, handleSelect, setOpen, max, isMultiple, showOptions])

   return (
      <Box
         bg='global.elementBg'
         rounded='lg'
         p='10px'
         h='100%'
         position='relative'
      >
         <Box
            w='100%'
            h='100%'
            overflowY='auto'
            rounded='lg'
            id='fs'
            ref={container}
            zIndex={100}
            position='relative'
         ></Box>
         <Flex
            w='100%'
            h='100%'
            position='absolute'
            zIndex={50}
            top={0}
            left={0}
            align='center'
            justify='center'
         >
            <Spinner size='50px' />
         </Flex>
      </Box>
   )
}

export default Cloudinary
