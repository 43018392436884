/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import Box from '../Box'
import Flex from '../Flex'
import Icon from '../Icon'
import useAlertStyle, { useAlertIconStyle } from './styles'
import { FiInfo, FiAlertTriangle, FiXCircle } from 'react-icons/fi'
import { BiCheckCircle } from 'react-icons/bi'

export const statuses = {
  info: { icon: <FiInfo />, color: 'info' },
  warning: { icon: <FiAlertTriangle />, color: 'warning' },
  success: { icon: <BiCheckCircle />, color: 'success' },
  error: { icon: <FiXCircle />, color: 'error' }
}

const Alert = (props) => {
  const { status, variant, children, hasIcon, title, ...rest } = props
  const alertStyleProps = useAlertStyle({
    variant,
    color: statuses[status] && statuses[status].color
  })

  return (
    <Flex align='center' role='alert' {...alertStyleProps} {...rest}>
      {hasIcon && (
        <AlertIcon variant={variant} status={status}>
          {statuses[status].icon}
        </AlertIcon>
      )}
      <Flex direction='column'>
        <Box fontWeight='semibold' fontSize='1.1em' pr='30px'>
          {title}
        </Box>
        <Box>{children}</Box>
      </Flex>
    </Flex>
  )
}

/* eslint-disable no-restricted-globals */
const AlertIcon = (props) => {
  const iconStyleProps = useAlertIconStyle({
    variant: props.variant,
    color: statuses[props.status] && statuses[props.status].color
  })

  return (
    <Icon
      mr={3}
      fontSize={28}
      name={statuses[status] && statuses[status].icon}
      {...iconStyleProps}
      {...props}
    >
      {props.children}
    </Icon>
  )
}

Alert.displayName = 'Alert'

Alert.propTypes = {
  /**
   * The type or status of the alert
   */
  status: PropTypes.oneOf(['info', 'warning', 'success', 'error']),
  /**
   * The variant
   */
  variant: PropTypes.oneOf(['subtle', 'solid', 'plain']),
  /**
   * Show or hide icon
   */
  hasIcon: PropTypes.bool,
  /**
   * The Alert title
   */
  title: PropTypes.string,
  /**
   * The body of the alert message
   */
  children: PropTypes.node
}

Alert.defaultProps = {
  status: 'info',
  variant: 'subtle',
  hasIcon: true
}

export default Alert
