/** @jsx jsx */
import { jsx } from "@emotion/core";
import { forwardRef } from "react";
import PseudoBox from "../PseudoBox";

const Icon = forwardRef(
  (
    {
      color = "currentColor",
      role = "presentation",
      focusable = false,
      children,
      ...rest
    },
    ref
  ) => {
    return (
      <PseudoBox
        ref={ref}
        color={color}
        role={role}
        display="flex"
        verticalAlign="middle"
        {...rest}
      >
        {children}
      </PseudoBox>
    );
  }
);

Icon.displayName = "Icon";

export default Icon;
