import React from 'react'
import { useWatch } from 'react-hook-form'
import Field from '../../components/Form/Field'
import RepeaterConditionalFieldWrapper from '../../Fields/Repeater/RepeaterConditionalFieldWrapper'

const ConditionalField = (props) => {
   const {
      setActive,
      onChange,
      onBlur,
      value,
      condition,
      defaultValue,
      name,
      size,
      index,
      i,
      childProps,
      parentName,
      isRepeater,
      component,
      ...rest
   } = props
   const { watchField, matchValue, operator, repeater } = condition

   const watchInput = useWatch({
      name: repeater ? `${parentName}.${watchField}` : watchField
   })

   if (checkCondition(operator, watchInput, matchValue)) {
      return isRepeater ? (
         <RepeaterConditionalFieldWrapper component={component}>
            <Field {...props} isConditional />
         </RepeaterConditionalFieldWrapper>
      ) : (
         <Field {...props} isConditional />
      )
   } else {
      return null
   }
}

const checkCondition = (operator, watchInput, conditionValue) => {
   switch (operator) {
      case '===':
         return Array.isArray(conditionValue)
            ? conditionValue.some((element) => element?.id === watchInput?.id)
            : conditionValue === watchInput

      case '!==':
         return Array.isArray(conditionValue)
            ? conditionValue.some((element) => element?.id !== watchInput?.id)
            : conditionValue !== watchInput

      case '>':
         return watchInput > conditionValue

      case '>=':
         return watchInput >= conditionValue

      case '<':
         return watchInput < conditionValue
      case '<=':
         return watchInput <= conditionValue

      default:
         return Array.isArray(conditionValue)
            ? conditionValue.some((element) => element?.id === watchInput?.id)
            : conditionValue === watchInput
   }
}
export default ConditionalField
