function createCrud({ setup, name, edit, list, view = null }) {
   const url = name.replace(/\s+/g, '-').toLowerCase()

   const array = [
      {
         path:
            setup.canAddNew === true
               ? `/:model(${url})/:type(create)`
               : `/:model(${url})/not-allowed`,
         title: `${name} - Create`,
         exact: true,
         auth: true,
         component: edit
      },
      {
         path: `/:model(${url})/:type(edit)/:id`,
         title: `${name} - Edit`,
         exact: true,
         auth: true,
         component: edit
      },
      {
         path: `/:model(${url})`,
         title: `${name} - View All`,
         exact: true,
         auth: true,
         component: list
      }
   ]

   if (view !== null) {
      array.splice(1, 0, {
         path: `/:model(${url})/:type(view)/:id`,
         title: `${name} - View`,
         exact: true,
         auth: true,
         component: view
      })
   }

   return array
}

export default createCrud
